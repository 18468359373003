import { ConversationInboundStatus, CustomElementTags, SourceType } from '@/utility/Constants'
import type { BubbleConfig } from 'types/BubbleConfig'
import type { Conversation } from 'types/Conversation'
import type { UserDetails } from 'types/UserDetails'
import {
  SocketHandler,
  type ClientToServerEvents,
  type ServerToClientEvents
} from './SocketHandler'
import type { QRCode } from 'types/QRCode'
import type { Kiosk } from 'types/Kiosk'
import type { FormSubmission } from 'types/FormSubmission'
import type { Website } from 'types/Website'
import { API } from '@/utility/Api'
import type { Socket } from 'socket.io-client'
import type { LastActiveCall } from 'types/LastActiveCall'
import { Session } from './Session'
import type ResponseWithData from 'types/ResponseWithData'
import { captureException, setContext } from '@/bubble'
import { addBreadcrumb } from '@sentry/vue'
import { CustomElementHelper } from '@/utility/CustomElements'

enum AppState {
  Kiosk,
  Form,
  PermissionCheck,
  Calling,
  InCall,
  MissedCall,
  Ended
}

export class LiveSwitchConference {
  root: HTMLElement
  model: Kiosk | QRCode | Website
  config: BubbleConfig
  userDetails: UserDetails | undefined
  conversation: Conversation | undefined
  socketHandler: SocketHandler
  visibleUIComponent: HTMLElement | undefined
  isLiveswitchWindowed: boolean
  sourceType: SourceType
  hasPassedPermissions: boolean
  appState: AppState | undefined
  form: FormSubmission | undefined
  visitorId: string
  api: API = new API()
  constructor(
    rootElement: HTMLElement,
    config: BubbleConfig,
    model: Kiosk | QRCode | Website,
    userDetails: UserDetails | undefined,
    sourceType: SourceType
  ) {
    if (this.isInstanceOfTag(rootElement, CustomElementTags.WINDOW)) {
      this.root = rootElement
      this.isLiveswitchWindowed = true
      this.styleRootAsWindow(rootElement)
    } else {
      this.isLiveswitchWindowed = false
      this.root = rootElement
    }
    this.visitorId = ''
    this.model = model
    this.hasPassedPermissions = false
    this.sourceType = sourceType
    this.config = config
    this.userDetails = userDetails
    this.socketHandler = new SocketHandler()
    this.createSocketEventListeners()
    this.form = undefined
    setContext('conference', this)
    try {
      const lastActiveCallInformation: LastActiveCall | undefined = Session.getSession()
      /* Kiosks are not supporting rejoins. */
      if (lastActiveCallInformation && sourceType != SourceType.KIOSK) {
        this.config = lastActiveCallInformation.config
        this.userDetails = lastActiveCallInformation.userDetails
        this.conversation = lastActiveCallInformation.conversation
        this.sourceType = lastActiveCallInformation.sourceType as SourceType
        this.isLiveswitchWindowed = lastActiveCallInformation.isWindowed
        this.visitorId = lastActiveCallInformation.conversation.visitorId
        this.socketHandler.connect(
          this.visitorId,
          (socket: Socket<ServerToClientEvents, ClientToServerEvents>) => {
            this.handleTransitionEvent()
          }
        )
      } else {
        this.appState = this.initializeApp()
      }
    } catch (e) {
      captureException(e, {
        captureContext: {
          extra: {
            method: 'LiveSwitchConference.constructor',
            conference: this
          }
        }
      })
      /* Some browsers have sessionStorage blocked or disabled. Nothing to do here, reconnection won't be supported. */
      this.appState = this.initializeApp()
    }
  }

  isInstanceOfTag(element: HTMLElement, tag: string) {
    return element.tagName.toLowerCase() === tag
  }

  async closeWindow(forceTransition: boolean = false) {
    try {
      addBreadcrumb({
        category: 'LiveSwitchConference',
        message: 'Called closeWindow.',
        level: 'info'
      })
      Session.removeExistingSession()
      if (this.conversation && this.visitorId) {
        await this.api.putAuth(`/public/conversations/${this.conversation.id}/end`, {
          conversationId: this.conversation.id,
          visitorId: this.visitorId
        })
        addBreadcrumb({
          category: 'LiveSwitchConference',
          message: 'Called end conversation.',
          level: 'info'
        })
      }
    } catch (e) {
      captureException(e, {
        captureContext: {
          extra: {
            method: 'LiveSwitchConference.closeWindow',
            conference: this
          }
        }
      })
    }
    this.conversation = undefined
    this.userDetails = undefined
    this.socketHandler.disconnect()
    if (forceTransition) {
      addBreadcrumb({
        category: 'LiveSwitchConference',
        message: 'Forcing Kiosk transition state to ended.',
        level: 'info'
      })
      return this.transitionState(AppState.Ended)
    }
    if (this.sourceType === SourceType.KIOSK) {
      addBreadcrumb({
        category: 'LiveSwitchConference',
        message: 'Reloading page.',
        level: 'info'
      })
      window.location.reload()
    } else if (this.sourceType === SourceType.QRCODE) {
      addBreadcrumb({
        category: 'LiveSwitchConference',
        message: 'Forcing QR Code transition state to ended.',
        level: 'info'
      })
      this.transitionState(AppState.Ended)
      return
    } else if (this.root) {
      try {
        addBreadcrumb({
          category: 'LiveSwitchConference',
          message: 'Dispatching close event.',
          level: 'info'
        })
        this.root.dispatchEvent(new CustomEvent('close'))
      } catch (e) {
        /* empty */
      }
      addBreadcrumb({
        category: 'LiveSwitchConference',
        message: 'Removing element from page.',
        level: 'info'
      })
      this.root.remove()
    } else {
      addBreadcrumb({
        category: 'LiveSwitchConference',
        message: 'State not known, transitioning to ended.',
        level: 'info'
      })
      this.transitionState(AppState.Ended)
    }
  }

  styleRootAsWindow(rootElement: HTMLElement) {
    addBreadcrumb({
      category: 'LiveSwitchConference',
      message: 'Style root as window.',
      level: 'info'
    })
    const windowDecoration = CustomElementHelper.createCustomElement(
      CustomElementTags.WINDOW_DECORATION
    )
    windowDecoration.style.position = 'sticky'
    windowDecoration.style.top = '0'
    windowDecoration.style.zIndex = '10'

    windowDecoration.addEventListener('close', () => {
      this.closeWindow()
    })

    let currentPosX = 0,
      currentPosY = 0,
      previousPosX = 0,
      previousPosY = 0
    let isDragging = false

    const startDrag = (e: MouseEvent | TouchEvent) => {
      try {
        e.preventDefault()
        if (rootElement.hasAttribute('maximized')) {
          return
        }
        const isMouseEvent = (e: MouseEvent | TouchEvent): e is MouseEvent =>
          (e as MouseEvent).clientX !== undefined
        if (isMouseEvent(e)) {
          if (e.button !== 0) return
          previousPosX = e.clientX
          previousPosY = e.clientY
        } else {
          previousPosX = e.touches[0].clientX
          previousPosY = e.touches[0].clientY
        }
        isDragging = true
        document.addEventListener('mousemove', dragging)
        document.addEventListener('mouseup', endDrag)
        document.addEventListener('touchmove', dragging, { passive: false })
        document.addEventListener('touchend', endDrag)
      } catch (e) {
        captureException(e)
      }
    }

    const dragging = (e: MouseEvent | TouchEvent) => {
      try {
        e.preventDefault()
        let clientX, clientY
        const isMouseEvent = (e: MouseEvent | TouchEvent): e is MouseEvent =>
          (e as MouseEvent).clientX !== undefined
        if (isMouseEvent(e)) {
          clientX = e.clientX
          clientY = e.clientY
        } else {
          clientX = e.touches[0].clientX
          clientY = e.touches[0].clientY
        }
        // Calculate the new cursor position by using the previous x and y positions of the mouse
        currentPosX = previousPosX - clientX
        currentPosY = previousPosY - clientY
        // Replace the previous positions with the new x and y positions of the mouse
        previousPosX = clientX
        previousPosY = clientY
        // Set the element's new position
        // Calculate the new position
        const newTop = rootElement.offsetTop - currentPosY
        const newLeft = rootElement.offsetLeft - currentPosX
        // Get viewport dimensions
        const viewportWidth = window.innerWidth
        const viewportHeight = window.innerHeight
        // Get element dimensions
        const elementWidth = rootElement.offsetWidth
        const elementHeight = rootElement.offsetHeight
        // Ensure the new position is within the viewport bounds
        const boundedTop = Math.max(0, Math.min(viewportHeight - elementHeight, newTop))
        const boundedLeft = Math.max(0, Math.min(viewportWidth - elementWidth, newLeft))
        // Apply the bounded position
        rootElement.style.top = `${boundedTop}px`
        rootElement.style.left = `${boundedLeft}px`
        rootElement.removeAttribute('position')
      } catch (e) {
        captureException(e)
      }
    }

    const endDrag = (e: MouseEvent | TouchEvent) => {
      try {
        if (!isDragging) return
        e.preventDefault()
        isDragging = false
        document.removeEventListener('mousemove', dragging)
        document.removeEventListener('mouseup', endDrag)
        document.removeEventListener('touchmove', dragging)
        document.removeEventListener('touchend', endDrag)
      } catch (e) {
        captureException(e)
      }
    }

    windowDecoration.addEventListener('mousedown', startDrag)
    windowDecoration.addEventListener('touchstart', startDrag, { passive: false })

    windowDecoration.addEventListener('maximize', () => {
      if (rootElement.hasAttribute('maximized')) {
        rootElement.removeAttribute('maximized')
      } else {
        rootElement.setAttribute('maximized', 'true')
        rootElement.removeAttribute('minimized')
      }
    })

    windowDecoration.addEventListener('minimize', () => {
      if (rootElement.hasAttribute('minimized')) {
        rootElement.removeAttribute('minimized')
      } else {
        rootElement.setAttribute('minimized', 'true')
        rootElement.removeAttribute('maximized')
      }
    })

    // Function to check and adjust the div's position
    function adjustDivPosition() {
      const rect = rootElement.getBoundingClientRect()
      const viewportWidth = globalThis.innerWidth
      const viewportHeight = globalThis.innerHeight

      // Check if the div is outside the viewport on the right side
      if (rect.right > viewportWidth) {
        // Adjust the left position so the div is fully visible
        rootElement.style.left = `${viewportWidth - rect.width}px`
      }

      // Check if the div is outside the viewport on the bottom
      if (rect.bottom > viewportHeight) {
        // Adjust the top position so the div is fully visible
        rootElement.style.top = `${viewportHeight - rect.height}px`
      }
    }

    // Add event listener for window resize
    globalThis.addEventListener('resize', adjustDivPosition)

    rootElement.shadowRoot!.appendChild(windowDecoration)
  }

  startAConversation() {
    addBreadcrumb({
      category: 'LiveSwitchConference',
      message: 'startConversation called.',
      level: 'info'
    })
    this.conversation = undefined
    this.visitorId = crypto.randomUUID()
    setContext('conference', this)
    this.socketHandler.connect(
      this.visitorId,
      async (socket: Socket<ServerToClientEvents, ClientToServerEvents>) => {
        await this.createNewConversationRecord()
      }
    )
  }

  private async createNewConversationRecord() {
    try {
      addBreadcrumb({
        category: 'LiveSwitchConference',
        message: 'Create new conversation record called.',
        level: 'info'
      })
      if (this.conversation) {
        addBreadcrumb({
          category: 'LiveSwitchConference',
          message: 'Skipping conversation creation due to record already existing.',
          level: 'warning'
        })
        // This happens when the user disconnected long enough to disconnect from both socket and the meeting, then rejoined.
        return
      }
      const data: FormSubmission = this.form ?? ({} as FormSubmission)
      if (this.sourceType === SourceType.KIOSK) {
        data.kioskId = this.model.id
      } else if (this.sourceType === SourceType.QRCODE) {
        data.qrCodeId = this.model.id
      } else if (this.sourceType === SourceType.WEBSITE) {
        data.websiteId = this.model.id
      }
      data.visitorId = this.visitorId
      data.inboundSource = {
        title: document?.title || '',
        link: window?.location?.href || ''
      }
      setContext('formSubmission', data)
      setContext('conference', this)
      await this.api.postAuth(`/form-submissions/public`, data, {})
      addBreadcrumb({
        category: 'LiveSwitchConference',
        message: 'Successfully created conversation record.',
        level: 'info'
      })
    } catch (e) {
      captureException(e, {
        captureContext: {
          extra: {
            method: 'LiveSwitchConference.createNewConversationRecord',
            conference: this
          }
        }
      })
      await this.closeWindow(true)
    }
  }

  private initializeApp(): AppState | undefined {
    if (this.sourceType === SourceType.KIOSK) {
      addBreadcrumb({
        category: 'LiveSwitchConference',
        message: 'Initializing app state to Kiosk.',
        level: 'info'
      })
      return this.transitionState(AppState.Kiosk)
    } else if (this.sourceType === SourceType.QRCODE) {
      if (this.model.showForm) {
        addBreadcrumb({
          category: 'LiveSwitchConference',
          message: 'Initializing app state to form.',
          level: 'info'
        })
        return this.transitionState(AppState.Form)
      } else {
        addBreadcrumb({
          category: 'LiveSwitchConference',
          message: 'Initializing app state to permission check.',
          level: 'info'
        })
        return this.transitionState(AppState.PermissionCheck)
      }
    } else if (this.sourceType === SourceType.WEBSITE) {
      addBreadcrumb({
        category: 'LiveSwitchConference',
        message: 'Initializing app state to form.',
        level: 'info'
      })
      return this.transitionState(AppState.Form)
    }
    return undefined
  }

  private async handleFormSubmit(ev: CustomEvent<Array<FormSubmission>>) {
    try {
      if (ev.detail && ev.detail.length > 0) {
        this.form = ev.detail[0] as FormSubmission
      }
      if (this.appState === AppState.MissedCall) {
        if (this.form) {
          setContext('MissedCall', this.form)
          addBreadcrumb({
            category: 'LiveSwitchConference',
            message: 'Submitting missed call.',
            level: 'info'
          })
          await this.api.putAuth(`/form-submissions/public/missed`, {
            id: this.conversation?.id,
            form: this.form
          })
          addBreadcrumb({
            category: 'LiveSwitchConference',
            message: 'Successfully submitted missed call.',
            level: 'info'
          })
        }
      }
    } catch (e) {
      captureException(e, {
        captureContext: {
          extra: { method: 'LiveSwitchConference.handleFormSubmit', conference: this }
        }
      })
      await this.closeWindow(true)
    }
  }

  private handleTransitionEvent() {
    try {
      addBreadcrumb({
        category: 'LiveSwitchConference',
        message: `Handle transition event ${this.appState}`,
        level: 'info'
      })
      if (this.appState === AppState.Kiosk) {
        if (this.model.showForm) {
          this.transitionState(AppState.Form)
        } else {
          this.transitionState(AppState.PermissionCheck)
        }
      } else if (this.appState === AppState.Form && this.form) {
        this.transitionState(AppState.PermissionCheck)
      } else if (this.appState === AppState.PermissionCheck) {
        this.startAConversation()
        this.transitionState(AppState.Calling)
      } else if (
        this.conversation &&
        (this.conversation.inboundStatus === ConversationInboundStatus.AwaitingAnyPickup ||
          this.conversation?.inboundStatus === ConversationInboundStatus.AwaitingSpecificPickup) &&
        this.appState !== AppState.Calling
      ) {
        this.transitionState(AppState.Calling)
      } else if (
        this.conversation &&
        this.conversation.inboundStatus === ConversationInboundStatus.InProgress &&
        this.appState !== AppState.InCall
      ) {
        this.transitionState(AppState.InCall)
      } else if (
        this.conversation &&
        this.conversation.inboundStatus === ConversationInboundStatus.Missed &&
        !this.conversation.followupNotes &&
        this.appState != AppState.MissedCall
      ) {
        this.transitionState(AppState.MissedCall)
      } else if (
        this.conversation &&
        (this.conversation.inboundStatus === ConversationInboundStatus.Ended ||
          (this.conversation.inboundStatus === ConversationInboundStatus.Missed &&
            this.conversation.followupNotes)) &&
        this.appState !== AppState.Ended
      ) {
        this.transitionState(AppState.Ended)
      } else if (
        this.conversation &&
        this.conversation.inboundStatus === ConversationInboundStatus.Abandoned
      ) {
        this.transitionState(AppState.Ended)
      }
    } catch (e) {
      captureException(e)
      this.closeWindow(true)
    }
  }

  private handleUserDetails(ev: CustomEvent<UserDetails>) {
    //@ts-expect-error
    if (ev.detail && ev.detail.length > 0) {
      //@ts-expect-error Custom events are for some reason not supported.
      this.userDetails = ev.detail[0] as UserDetails
      setContext('conference', this)
    }
  }

  private transitionState(state: AppState): AppState {
    try {
      if (this.appState === state) {
        // Don't re-render the same state.
        return state
      }
      addBreadcrumb({
        category: 'LiveSwitchConference',
        message: `State transitioning to ${state}`,
        level: 'info'
      })
      this.appState = state
      setContext('conference', this)
      switch (state) {
        case AppState.Kiosk:
          this.renderToUI(
            CustomElementHelper.createCustomElement(CustomElementTags.KIOSK_HOME, {
              kiosk: this.model
            })
          )
          break
        case AppState.Form:
          this.renderToUI(
            CustomElementHelper.createCustomElement(CustomElementTags.FORM, {
              use: 'Form',
              config: this.config,
              formSubmission: this.form || null,
              form: this.model.form,
              model: this.model,
              sourceType: this.sourceType,
              userDetails: this.userDetails,
              isWindowed: this.isLiveswitchWindowed
            })
          )
          break
        case AppState.PermissionCheck:
          this.renderToUI(
            CustomElementHelper.createCustomElement(CustomElementTags.PERMISSIONS_CHECK, {
              sourceType: this.sourceType
            })
          )
          break
        case AppState.Calling:
          this.renderToUI(
            CustomElementHelper.createCustomElement(CustomElementTags.DIALING_IN, {
              config: this.config,
              isWindowed: this.isLiveswitchWindowed,
              sourceType: this.sourceType
            })
          )
          break
        case AppState.InCall:
          const cmp = CustomElementHelper.createCustomElement(CustomElementTags.IN_CALL, {
            config: this.config,
            userDetails: this.userDetails,
            conversation: this.conversation,
            isWindowed: this.isLiveswitchWindowed,
            sourceType: this.sourceType
          })
          cmp.style.height = '100%'
          cmp.style.width = '100%'
          cmp.style.overflow = 'hidden'
          this.renderToUI(cmp)
          break
        case AppState.MissedCall:
          Session.removeExistingSession()
          this.renderToUI(
            CustomElementHelper.createCustomElement(CustomElementTags.FORM, {
              use: 'Missed',
              config: this.config,
              formSubmission: this.form,
              form: this.model.form,
              model: this.model,
              sourceType: this.sourceType
            })
          )
          break
        case AppState.Ended:
          Session.removeExistingSession()
          this.renderToUI(
            CustomElementHelper.createCustomElement(CustomElementTags.END_CALL, {
              config: this.config,
              isWindowed: this.isLiveswitchWindowed,
              sourceType: this.sourceType
            })
          )
          break
      }
      return state
    } catch (e) {
      captureException(e)
      if (state != AppState.Ended) {
        this.closeWindow(true)
      }
      throw e
    }
  }

  private renderToUI(cmp: HTMLElement) {
    if (this.visibleUIComponent) {
      this.visibleUIComponent.remove()
      this.visibleUIComponent = undefined
    }
    if (this.isLiveswitchWindowed) {
      cmp.style.margin = 'auto 0'
    }
    if (!this.root.shadowRoot) {
      this.root.appendChild(cmp)
    } else {
      this.root.shadowRoot!.appendChild(cmp)
    }
    addBreadcrumb({
      category: 'LiveSwitchConference',
      message: 'Rendering new component to UI.',
      level: 'info'
    })
    setContext('currentUIElement', cmp)
    this.visibleUIComponent = cmp
    cmp.addEventListener('close', () => {
      this.closeWindow()
    })
    cmp.addEventListener('transition', this.handleTransitionEvent.bind(this))
    //@ts-expect-error Custom events are not supported.
    cmp.addEventListener('formSubmit', this.handleFormSubmit.bind(this))
    //@ts-expect-error Custom events are not supported.
    cmp.addEventListener('userDetails', this.handleUserDetails.bind(this))
  }

  private createSocketEventListeners() {
    // This happens via the webhooks, they fire the conversation update event for users connected to the socket.
    this.socketHandler.addEventListener('conversation_update', (event: Event) => {
      try {
        addBreadcrumb({
          category: 'LiveSwitchConference',
          message: 'Conversation update event received.',
          level: 'info'
        })
        const customEvent = event as CustomEvent<Conversation>
        const conversation = customEvent.detail
        if (conversation.visitorId === this.visitorId) {
          setContext('conversation', conversation)
          this.conversation = conversation
          this.handleTransitionEvent()
        }
      } catch (e) {
        captureException(e, {
          captureContext: {
            extra: {
              method: 'LiveSwitchConference.createSocketEventListeners',
              eventListener: 'conversation_update'
            }
          }
        })
      }
    })
    this.socketHandler.addEventListener('reconnect', async () => {
      try {
        // In the event you were disconnected from socket then re-connected.
        // There may have been status updates you missed, grab the latest conversation object
        // And run any transitions that may need to happen.
        if (this.conversation && this.visitorId) {
          addBreadcrumb({
            category: 'LiveSwitchConference',
            message: 'Reconnect event received.',
            level: 'info'
          })
          const resp: ResponseWithData = await this.api.getAuth(
            `/public/conversations/${this.conversation.id}`,
            {}
          )
          // Note the insecure endpoint for conversations is intentionally vege and we do not want to use it as the conversation object.
          // We only need properties such as the status in order to handle any status changes that may have happened.
          const mergedObject = Object.assign({}, this.conversation, resp.data)
          this.conversation = mergedObject
          this.handleTransitionEvent()
        }
      } catch (e) {
        captureException(e, {
          captureContext: {
            extra: {
              method: 'LiveSwitchConference.createSocketEventListeners',
              eventListener: 'reconnect'
            }
          }
        })
      }
    })
  }
}
