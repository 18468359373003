export class CustomElementHelper {
  // Recommended, will create a HTML Element with your parameters passed in.
  static createCustomElement(elementName: string, parameters?: object) {
    const element = document.createElement(elementName)
    for (const prop in parameters) {
      //@ts-expect-error
      element[prop] = parameters[prop]
    }
    return element
  }
}
