import type { SentryScope } from '@/bubble'
import * as Sentry from '@sentry/browser'

export interface SentryClient {
  init(): any
}

const sharedVariables = {
  dsn: 'https://fd0262ce954642cf896fcf1c0d5185e0@o4504596163002368.ingest.us.sentry.io/4504686451621888',
  tracePropagationTargets: [
    /^https:\/\/(?:.+\.)?liveswitch\.com$/,
    /^https:\/\/(?:.+\.)?ngrok\.app$/,
    /^https:\/\/(?:.+\.)?ngrok\.dev$/
  ],
  sendDefaultPii: true
}

class SharedSentry implements SentryClient {
  init(): SentryScope {
    Sentry.init({
      dsn: sharedVariables.dsn,
      sendDefaultPii: sharedVariables.sendDefaultPii,
      release: process.env.APP_VERSION,
      environment: process.env.NODE_ENV,
      integrations: [
        Sentry.captureConsoleIntegration({ levels: ['error', 'warn'] }),
        Sentry.contextLinesIntegration(),
        Sentry.extraErrorDataIntegration(),
        Sentry.httpClientIntegration(),
        Sentry.reportingObserverIntegration(),
        Sentry.browserSessionIntegration(),
        Sentry.browserTracingIntegration(),
        Sentry.browserProfilingIntegration()
      ],
      tracePropagationTargets: sharedVariables.tracePropagationTargets
    })
    return Sentry
  }
  getTestType() {
    return 'shared'
  }
}

class IsolatedSentry implements SentryClient {
  init(): SentryScope {
    const integrations = Sentry.getDefaultIntegrations({}).filter((defaultIntegration) => {
      return !['BrowserApiErrors', 'Breadcrumbs', 'GlobalHandlers'].includes(
        defaultIntegration.name
      )
    })
    const client = new Sentry.BrowserClient({
      dsn: sharedVariables.dsn,
      transport: Sentry.makeFetchTransport,
      stackParser: Sentry.defaultStackParser,
      integrations: integrations,
      sendDefaultPii: sharedVariables.sendDefaultPii,
      release: process.env.APP_VERSION,
      environment: process.env.NODE_ENV,
      tracePropagationTargets: sharedVariables.tracePropagationTargets
    })
    const scope = new Sentry.Scope()
    scope.setClient(client)
    client.init() // initializing has to be done after setting the client on the scope
    return scope
  }
  getTestType() {
    return 'isolated'
  }
}

export class SentryFactory {
  static createSentry(): SentryClient {
    try {
      const hostname = window.location.hostname
      const domain = hostname.replace(/^https?:\/\/(www\.)?/, '')
      const parts = domain.split('.')
      // We don't want to use shared sentry on LiveSwitch.com anymore only the subdomains. We are getting too many errors from them.
      if (parts.length === 2 && parts[0] === 'liveswitch' && parts[1] === 'com') {
        return new IsolatedSentry() // Exclude "liveswitch.com" and "www.liveswitch.com"
      } else if (parts.length > 2 && parts.slice(-2).join('.') === 'liveswitch.com') {
        return new SharedSentry() // Accept subdomains like "beacon.concierge.liveswitch.com"
      } else {
        return new IsolatedSentry() // Handle other domains
      }
    } catch (e) {
      // Always default to the IsolatedSentry.
      return new IsolatedSentry()
    }
  }
}
